export default {
  header: "Menu",
  children: [
    {
      id: "dashboard",
      title: "Dashboard",
      icon: "iconly-Curved-Home",
      iconly: true,
      navLink: "/admin",
      isComingSoon: false,
    },
    {
      id: "members",
      title: "Members",
      icon: "iconly-Curved-TwoUsers",
      iconly: true,
      navLink: "/admin/members",
      isComingSoon: false,
    },
    {
      id: "genopets-admin",
      title: "Genopets Admin",
      icon: "iconly-Curved-Game",
      iconly: true,
      navLink: "/admin/genopets",
      isComingSoon: false,
    },
    /*{
      id: "faq",
      title: "FAQ (coming soon)",
      icon: "Curved-Category",
      navLink: "/admin/faq",
      isComingSoon: true,
    },
    {
      id: "knowledgebase",
      title: "Knowledge base (coming soon)",
      icon: "Curved-Document",
      navLink: "/admin/knowledgebase",
      isComingSoon: true,
    },*/
    {
      id: "portal-settings",
      title: "Portal settings",
      icon: "iconly-Curved-Setting",
      iconly: true,
      navLink: "/admin/portal-settings",
      isComingSoon: false,
    },
    {
      id: "back-to-portal",
      title: "Back to Portal",
      icon: "iconly-Curved-ArrowLeftSquare",
      iconly: true,
      navLink: "/",
      isComingSoon: false,
    },
  ],
};
