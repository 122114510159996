var render = function render(){var _vm=this,_c=_vm._self._c;return _c('li',[(_vm.item.children)?_c('a',{directives:[{name:"b-toggle",rawName:"v-b-toggle",value:(`sub-collapse-${_vm.item.id}`),expression:"`sub-collapse-${item.id}`"}],class:`submenu-item${
      _vm.path.split('/')[2] == _vm.item.id
        ? (_vm.isShow = true + ' active arrow-active')
        : _vm.isShow
        ? ' arrow-active'
        : ''
    }`,attrs:{"href":"javascript:;"},on:{"click":function($event){_vm.isShow = !_vm.isShow}}},[_c('span',[(_vm.item.iconly)?_c('i',{class:_vm.item.icon}):_c('i',{class:_vm.item.icon,staticStyle:{"zoom":"180%"}}),_c('span',[_vm._v(_vm._s(_vm.item.title))])]),_c('div',{staticClass:"menu-arrow"})]):_c('router-link',{class:`${_vm.path == _vm.item.navLink ? 'active' : ''}`,attrs:{"to":_vm.item.navLink}},[(_vm.collapsed)?_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.right",value:({ customClass: 'sidebar-tooltip' }),expression:"{ customClass: 'sidebar-tooltip' }",modifiers:{"hover":true,"right":true}}],attrs:{"title":_vm.item.title}},[(_vm.item.iconly)?_c('i',{class:_vm.item.icon}):_c('i',{class:_vm.item.icon,staticStyle:{"zoom":"180%"}}),_c('span',[_vm._v(_vm._s(_vm.item.title))])]):_c('span',[(_vm.item.iconly)?_c('i',{class:_vm.item.icon}):_c('i',{class:_vm.item.icon,staticStyle:{"zoom":"180%","margin-right":"3px"}}),_c('span',[_vm._v(_vm._s(_vm.item.title))])])]),_c('b-collapse',{class:_vm.collapsed ? 'd-none' : '',attrs:{"id":`sub-collapse-${_vm.item.id}`,"visible":_vm.path.split('/')[2] == _vm.item.id ? true : false}},[(_vm.item.children)?_c('ul',{staticClass:"submenu-children",attrs:{"data-level":"1"}},_vm._l((_vm.item.children),function(item,index){return _c('li',{key:index},[(item.children)?_c('a',{directives:[{name:"b-toggle",rawName:"v-b-toggle",value:(`sub-collapse-${item.id}`),expression:"`sub-collapse-${item.id}`"}],class:`submenu-item${
            _vm.path.split('/')[3] == item.id
              ? ' active arrow-active ' + (_vm.isShow2 = true)
              : _vm.isShow2
              ? ' arrow-active'
              : ''
          }`,attrs:{"href":"javascript:;"},on:{"click":function($event){_vm.isShow2 = !_vm.isShow2}}},[_c('span',[_vm._v(_vm._s(item.title))]),_c('div',{staticClass:"menu-arrow"})]):(item.target)?_c('a',{class:`${_vm.path == item.navLink ? 'active' : ''}`,attrs:{"href":item.navLink,"target":item.target}},[_c('span',[_vm._v(_vm._s(item.title))])]):_c('router-link',{class:`${_vm.path == item.navLink ? 'active' : ''}`,attrs:{"to":item.navLink}},[_c('span',[_vm._v(_vm._s(item.title))])]),_c('b-collapse',{class:_vm.collapsed ? 'd-none' : '',attrs:{"id":`sub-collapse-${item.id}`,"visible":_vm.path.split('/')[3] == item.id ? true : false}},[(item.children)?_c('ul',{staticClass:"submenu-children",attrs:{"data-level":"2"}},_vm._l((item.children),function(item,index){return _c('li',{key:index},[_c('router-link',{class:`${_vm.path == item.navLink ? 'active' : ''}`,attrs:{"to":item.navLink}},[_c('span',[_vm._v(_vm._s(item.title))])])],1)}),0):_vm._e()])],1)}),0):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }