<template>
  <div
    class="hover-dropdown-fade w-auto px-0 ml-6 position-relative hp-cursor-pointer"
  >
    <b-avatar variant="none" :src="profilPic" size="40px" />

    <div
      class="hp-header-profile-menu dropdown-fade position-absolute pt-18"
      style="top: 100%; width: 260px"
    >
      <div
        class="rounded border hp-border-color-black-40 hp-bg-black-0 hp-bg-dark-100 hp-border-color-dark-80 p-24"
      >
        <span
          class="d-block h5 hp-text-color-black-100 hp-text-color-dark-0 mb-6"
        >
          Hello {{ username }} 👋
        </span>

        <b-link
          to="/profile"
          class="hp-p1-body hp-text-color-primary-1 hp-text-color-dark-primary-1 hp-hover-text-color-primary-2 hp-hover-text-color-dark-primary-2"
        >
          View Profile
        </b-link>

        <!--<div class="divider my-12"></div>

        <b-row>
          <b-col cols="12">
            <b-link
              to="/profile/application"
              class="d-flex align-items-center hp-p1-body py-4 px-10 hp-transition hp-hover-bg-primary-1 hp-hover-bg-dark-primary-1 hp-hover-text-color-dark-0 rounded"
              style="margin-left: -10px; margin-right: -10px"
            >
              <i class="iconly-Curved-Paper mr-8" style="font-size: 16px"></i>

              <span>My Application</span>
            </b-link>
          </b-col>
          <b-col cols="12">
            <b-link
              to="/support/tickets"
              class="d-flex align-items-center hp-p1-body py-4 px-10 hp-transition hp-hover-bg-primary-1 hp-hover-bg-dark-primary-1 hp-hover-text-color-dark-0 rounded"
              style="margin-left: -10px; margin-right: -10px"
            >
              <i
                class="iconly-Bold-ShieldDone mr-8"
                style="font-size: 16px"
              ></i>

              <span>Support</span>
            </b-link>
          </b-col>
        </b-row>-->

        <div v-if="isAdmin">
          <div class="divider my-12"></div>

          <b-row>
            <b-col cols="12">
              <b-link
                to="/admin"
                class="d-flex align-items-center hp-p1-body py-4 px-10 hp-transition hp-hover-bg-dark-total hp-hover-bg-dark-dark-total hp-hover-text-color-dark-0 rounded"
                style="margin-left: -10px; margin-right: -10px"
              >
                <i class="iconly-Curved-Home mr-8" style="font-size: 16px"></i>

                <span>Admin</span>
              </b-link>
            </b-col>
          </b-row>
        </div>

        <div class="divider my-12"></div>

        <b-link
          class="d-flex align-items-center hp-p1-body py-4 px-10 hp-transition hp-hover-bg-danger-1 hp-hover-bg-dark-danger-1 hp-hover-text-color-dark-0 rounded"
          href="/logout"
          style="margin-left: -10px; margin-right: -10px"
        >
          <i class="iconly-Curved-Logout mr-8" style="font-size: 16px"></i>
          Logout
        </b-link>
      </div>
    </div>
  </div>
</template>

<script>
import { BRow, BCol, BAvatar, BLink } from "bootstrap-vue";

export default {
  components: {
    BRow,
    BCol,
    BAvatar,
    BLink,
  },
  data() {
    return {
      username: this.$store.state.auth.user.username,
      profilPic: this.$store.state.auth.user.avatar,
      isAdmin: false,
    };
  },
  mounted() {
    var portalRole = this.$store.state.auth.user.portalRole;
    if (portalRole != "user") {
      this.isAdmin = true;
    }
  },
};
</script>
