import userMenu from "./userMenu";
var onboardedFor = JSON.parse(localStorage.getItem("user")).onboardedFor;
var portalRole = JSON.parse(localStorage.getItem("user")).portalRole;

var menu = {
  header: "Menu",
  children: [
    {
      id: "dashboard",
      title: "Home",
      icon: "iconly-Curved-Home",
      iconly: true,
      navLink: "/",
    },
    {
      id: "profile",
      title: "Profile",
      icon: "iconly-Curved-User",
      iconly: true,
      navLink: "/profile",
    },
    {
      id: "usergames",
      title: "My Games",
      icon: "iconly-Curved-Game",
      iconly: true,
      children: [],
    },
    {
      id: "allgames",
      title: "Other Games",
      icon: "iconly-Curved-Game",
      iconly: true,
      children: [],
    },
    {
      id: "treasury",
      title: "Treasury",
      icon: "ri-money-dollar-circle-line",
      iconly: false,
      navLink: "/treasury",
    },
    {
      id: "discord",
      title: "Discord",
      icon: "iconly-Curved-Chat",
      iconly: true,
      navLink: "/discord",
    },
    {
      id: "livestream",
      title: "Live Stream",
      icon: "iconly-Curved-Video",
      iconly: true,
      navLink: "/livestream",
    },
    {
      id: "support",
      title: "Support",
      icon: "iconly-Bold-ShieldDone",
      iconly: true,
      children: [
        //{
        //  id: "support-faq",
        //  title: "FAQ",
        //  navLink: "/support/faq",
        //},
        {
          id: "support-knowledgebase",
          title: "Knowledge Base (coming soon)",
          //navLink: "/support/knowledgebase",
          navLink: "#",
        },
        {
          id: "support-tickets",
          title: "Support Tickets",
          navLink: "/support/tickets",
        },
      ],
    },
  ],
};

if (onboardedFor.length > 0) {
  var games = {
    milliononmars: "Million on Mars",
    axieinfinity: "Axie Infinity",
    sunflowerland: "Sunflower Land",
    genopets: "Genopets",
    honeyland: "Honey Land",
    legendsofvenari: "Legends of Venari",
    illuvium: "Illuvium",
    godsunchained: "Gods Unchained",
    bigtime: "Big Time",
  };
  for (var i = 0; i < onboardedFor.length; i++) {
    menu.children[2].children.push({
      id: "game-" + onboardedFor[i],
      title: games[onboardedFor[i]],
      navLink: "/games/" + onboardedFor[i],
    });
  }
} else {
  menu.children[2].children.push({
    id: "game-none",
    title: "No Games Here :(",
    navLink: "#",
  });
}

if (portalRole != "user") {
  menu.children.push({
    id: "admin",
    title: "Admin",
    icon: "iconly-Bold-ShieldDone",
    iconly: true,
    navLink: "/admin",
  });
}

for (var i in games) {
  if (onboardedFor.indexOf(i) === -1) {
    menu.children[3].children.push({
      id: "game-" + i,
      title: games[i],
      navLink: "/games/" + i,
    });
  }
}

export default [menu];
